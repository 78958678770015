import * as XLSX from "xlsx/xlsx.mjs";

const download = (dataList,fileName,sheetName) => {
    try {
        let sheet = XLSX.utils.json_to_sheet(dataList);
        let wb = XLSX.utils.book_new();
        fileName+=".xlsx"
        XLSX.utils.book_append_sheet(wb, sheet, sheetName);
        XLSX.writeFile(wb, fileName);
    }catch(e){
        console.log('localStorage err: '+e)
    }
}

const upload  = async function(file,sheetName,heads){
    let dataList=[];
    let ab
    try{
        ab = await file.arrayBuffer()
    }catch(e){
        console.log("---文件读取失败")
        throw e
    }
      let wb = XLSX.read(ab);
      let sheet = wb.Sheets[sheetName];
      if (!sheet) throw new Error("没有'快检数据'sheet");

      dataList.push(
        ...XLSX.utils.sheet_to_json(sheet, {
          range: 1,
          header: heads,
          raw: false,
        })
      );
      return dataList
}


export {download,upload}